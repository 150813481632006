<template>
  <div class="wrap">
    <div class="nav">
      <div class="navList">
        <div @click="jump('1')">
          <span>首页</span>
          <span>Home</span>
        </div>
        <div @click="jump('2')">
          <span>服务案例</span>
          <span>Case</span>
        </div>
        <div @click="jump('3')" class="cur">
          <span>新闻资讯</span>
          <span>News</span>
        </div>
        <div @click="jump('4')">
          <span>业务伙伴</span>
          <span>Cooperation</span>
        </div>
        <div @click="jump('5')">
          <span>关于我们</span>
          <span>About</span>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="title">
        <p>软木地板在办公室中要若何保养？</p>
        <p>发布日期：2021-07-11</p>
      </div>
      <div class="text">
        <div>
          办公室软木地板应该怎么照顾护士？软木地板也遍及应用于办公室装修，具有环保、隔音、防潮的浸染，在市场上很受接待。你知道如何照顾护士软木地板吗？
        </div>
        <img src="../../public/imgs/news/text7.png" alt="" />

        <div>
          1.概述彩绘软木地和实木地板的养生结果确实很差。地板一般每半年打蜡一次；树脂耐磨层软木地板的一般环境与复合地板沟通。
        </div>
        <div>
          2.在利用了三到五年的都市里，地板或多或少城市磨损。发起从零开始涂地板，也就是用砂纸将磨损的地板轻轻打磨，一般环境下将污垢全部清理清洁，用干软布擦拭，从零开始涂可能局部涂聚酯薄膜。
        </div>
        <div>
          3.在利用软木地板的进程中，只管制止将沙粒带入室内，沙粒会被压入脚底下的弹性层，但当双脚分隔时，沙粒会再次弹出，垂直地板就会磨损。留意不要带入太多脏的沙粒，大概会导致主动磨损。假如发明沙粒，必需当即割断并清理清洁。不需要专门配备的吸尘器，也不消担忧受潮翘曲或发霉。
        </div>

        <div>
          以上是对主人在办公室装修中共用软木地板的一种保养。地板要想豁亮雅观，就必需定时调剂。
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
export default {
  name: "index",
  components: {
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    jump(index) {
      if (index == 1) {
        this.$router.push({
          path: "/",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/case",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/news",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/partner",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/me",
        });
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wrap {
  height: 100vh;
  width: 1920px;
  margin: 0 auto;
}
.cur {
  color: #028ccd !important;
}
.nav .navList {
  position: absolute;
  top: 25px;
  right: 300px;
  height: 50px;
}
/* 导航 */
.nav {
  width: 100%;
  height: 100px;
  background: #fff url("../../public/imgs/indexNew/logo.png") no-repeat 76px
    center;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 8888;
}
.navList span {
  display: block;
}
.navList div {
  text-align: center;
  float: left;
  line-height: 50px;
  height: 70px;
  width: 70px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  position: relative;
  top: 0;
  transition: top 0.5s;
}
.navList div span:nth-of-type(1) {
  opacity: 1;
  transition: all 0.5s;
}
.navList div span:nth-of-type(2) {
  opacity: 0;
  transition: all 0.5s;
}
.navList div:hover {
  top: -50px;
}
.navList div:hover span:nth-of-type(1) {
  opacity: 0;
}
.navList div:hover span:nth-of-type(2) {
  opacity: 1;
}
.main {
  width: 1920px;
  margin: 100px auto 50px;
}
.title {
  background: #eee;
  height: 150px;
  overflow: hidden;
}
.title p {
  width: 1200px;
  margin: 0 auto;
  text-align: left;
}
.title p:nth-of-type(1) {
  font-size: 30px;
  color: #1f1f1f;
  margin-top: 46px;
}
.title p:nth-of-type(2) {
  line-height: 34px;
  font-size: 12px;
  color: #a0a0a0;
}

.text {
  width: 1200px;
  margin: 0 auto;
}
.text div {
  font-size: 14px;
  color: #5b5b5b;
  line-height: 24px;
  margin-top: 70px;
}
.text img {
  width: 100%;
}
</style>
